import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slicker from "../components/slick"
import AniLink from "../components/AniLink"

import { services } from "../assets/js/helpers"

import shape2 from "../assets/images/vector/landing/shape-2.svg"
import shape3 from "../assets/images/vector/landing/shape-3.svg"
import shape4 from "../assets/images/vector/landing/shape-4.svg"
import shape5 from "../assets/images/vector/landing/shape-5.svg"
import shape6 from "../assets/images/vector/landing/shape-6.svg"
import shape7 from "../assets/images/vector/landing/shape-7.svg"

import circle1 from "../assets/images/vector/landing/circle-1.svg"

import banner from "../assets/images/landing/landing-banner.png";
import technologies from "../assets/images/landing/technologies-logo.png";
import brandBI from "../assets/images/landing/related-brands/bank-indonesia.png";
import brandBL from "../assets/images/landing/related-brands/bukalapak.png";
import brandPaprika from "../assets/images/landing/related-brands/paprika.png";
import brandRipple from "../assets/images/landing/related-brands/ripple.png";
import brandUph from "../assets/images/landing/related-brands/uph.png";

const brands = [brandBI, brandBL, brandPaprika, brandRipple, brandUph]

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Home"
        description="We empower the business technology department to a higher level with our skills, experiences, and commitment. You will love it once you choose us!"
      />
      <section className="landing-banner-section section-top-200 section-bottom-100">
        <span className="landing-shape-2">
          <img src={shape2} alt="landing-decor-shape-2" />
        </span>
        <span className="landing-shape-3">
          <img src={shape3} alt="landing-decor-shape-3" />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1 text-center text-md-left landing-banner-welcome">
              <div>
                <span className="small-caption color-cerulean-blue">
                  A brand new
                </span>
              </div>
              <div>
                <h1 className="title color-raven-black">
                  Software development service
                </h1>
              </div>
              <div className="subtitle color-sacramento-green">
                We empower the business technology department to a higher level
                with our skills, experiences, and commitment. You will love it
                once you choose us!
              </div>
            </div>

            <div className="col-md-6 d-flex mb-5 mb-md-0 order-1 order-md-2">
              <img className="landing-banner-image" src={banner} />
            </div>
          </div>
        </div>
      </section>

      <section className="section-100" id="services">
        <span className="landing-wave-1" />
        <div className="container text-center">
          <div className="small-caption mb-0 color-cerulean-blue">Services</div>
          <div className="title color-raven-black">
            Technology Custom Services
          </div>
          <div className="subtitle color-russian-green">
            You are running a business without an in-house technology
            department. Keep the focus on your business, and let us handle the
            technology department. Now you can have a technology solution in any
            packages you need.
          </div>
          <div className="section-50">
            <div className="landing-service">
              {services.map((value, key) => {
                return (
                  <AniLink to={value.link}>
                    <div key={key} className="landing-service-item">
                      <img src={value.image} alt={value.id} />
                      <div className="landing-service-item-description">
                        {value.title}
                      </div>
                    </div>
                  </AniLink>
                )
              })}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="button button-default">Work With Us</div>
          </div>
        </div>
      </section>

      <section className="section-100" id="how-we-work">
        <span className="landing-shape-4">
          <img src={shape4} alt="landing-decor-shape-4" />
        </span>
        <span className="landing-shape-5">
          <img src={shape5} alt="landing-decor-shape-5" />
        </span>
        <div className="container text-center">
          <div className="small-caption mb-0 color-cerulean-blue">
            How We Work
          </div>
          <div className="title color-raven-black">
            <i>" </i>We <span className="color-pine-green">grow</span> as our{" "}
            <span className="color-pine-green">clients grow</span>.<i>"</i>
          </div>
          <div className="subtitle color-russian-green">
            We help you analyze, design and develop faster.
          </div>
          <div className="landing-work">
            <AniLink to="/how-we-work#discover">
              <div className="landing-work-item">
                <div className="parallelogram discover" />
                <div className="work-description">Discover</div>
              </div>
            </AniLink>
            <AniLink to="/how-we-work#build">
              <div className="landing-work-item">
                <div className="parallelogram build" />
                <div className="work-description">Build</div>
              </div>
            </AniLink>

            <AniLink to="/how-we-work#staff">
              <div className="landing-work-item">
                <div className="parallelogram staff" />
                <div className="work-description">Staff</div>
              </div>
            </AniLink>

            <AniLink to="/how-we-work#support">
              <div className="landing-work-item">
                <div className="parallelogram support" />
                <div className="work-description">Support</div>
              </div>
            </AniLink>
          </div>
        </div>
      </section>

      <section className="section-100" id="clients">
        <span className="landing-shape-4">
          <img src={shape6} alt="landing-decor-shape-6" />
        </span>
        <span className="landing-shape-5">
          <img src={shape7} alt="landing-decor-shape-7" />
        </span>
        <div className="container">
          <div className="small-caption mb-0 text-center color-cerulean-blue">
            Clients
          </div>
          <div className="title text-center color-raven-black">
            Our Featured Clients
          </div>
          <div className="margin-100">
            <Slicker />
          </div>
        </div>

        <div className="container">
          <div className="subtitle mb-5 color-cerulean-blue text-center">
            Featured companies we have worked with
          </div>
          <div className="d-flex justify-content-around flex-wrap">
            {brands.map((value, idx) => (
              <div key={idx} className="landing-related-brand">
                <img src={value} style={{ width: 150 }} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className="section-100 section-bottom-150 text-center text-md-left"
        id="technologies"
      >
        <span className="landing-circle-1">
          <img src={circle1} alt="landing-decor-circle-1" />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <div className="small-caption mb-0 color-cerulean-blue">
                Technologies
              </div>
              <div className="title mb-3 color-raven-black font-bebas">
                {`</>`} Simple But Great
              </div>
              <div className="subtitle color-russian-green">
                We ensure all of our team and client able to have the technical
                knowledge so that we keep our technology excellent and
                straightforward.
              </div>
            </div>
            <div className="col-md-6">
              <img src={technologies} style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="contact">
          <div className="p-relative section-100 container text-center text-md-left">
            <div className="heading color-pine-green mb-2">
              Have a chat with us!
            </div>
            <div className="mb-5 color-spring-green">
              Discuss all of your digital needs and start build your business.
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
              target="_blank"
            >
              <div className="d-inline button button-primary">Chat with us</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
