import React from "react"
import Slider from "react-slick"
import topremitPortfolio from '../assets/images/portfolio/top-remit/portfolio-top-remit.png';

import AniLink from "./AniLink"

function Slicker() {


  const slickerData = [
    {
      name: "Top Remit",
      logo: null,
      description:
        "We work with TopRemit to give their customers peace of mind sending money worldwide safe, low-cost, and online.",
      image: topremitPortfolio,
      url: "/portfolio/top-remit",
      style: {
        maxWidth: "100%",
        margin: "auto",
      },
    },
    // {
    //   name: "kubi.do",
    //   logo: null,
    //   description: "We work with Kubi Do to make it easy for customer support to answer questions on social media.",
    //   image: 'images.kubido.childImageSharp.fluid',
    //   url: "/portfolio/kubi-do",
    //   style: {
    //     maxWidth: "100%",
    //     margin: "auto",
    //   },
    // },
  ]

  const settings = {
    // className: "center",
    // centerMode: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    pauseOnHover: true,
    autoplaySpeed: 4000,
  }
  return (
    <div>
      <Slider {...settings}>
        {slickerData.map((value, key) => {
          return (
            <div className="slick-container" key={key} style={{ width: 560 }}>
              <div className="row pb-4">
                <div className="col-md-4 text-center text-md-left mb-3 mb-md-0">
                  <div className="small-caption color-cerulean-blue mb-2">{value.name}</div>
                  <div className="color-raven-black">{value.description}</div>
                  <div className="my-4">
                    <AniLink to={value.url} className="button button-default button-small">
                      Learn more
                    </AniLink>
                  </div>
                </div>
                <div className="col-md-8">
                  <img src={value.image} style={value.style} alt={value.description} />
                  {/* <Img fluid={value.image} style={value.style} alt="Our client's product snapshot" /> */}
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default Slicker
